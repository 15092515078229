import React from "react"

import { CustomPageProps } from "@utils/types"
import { BaseLayout } from "@layouts/BaseLayout"
import { Form } from "@components/common/Form"

const FormPage: React.FC<CustomPageProps> = ({ pageContext, location }) => {
  return (
    <BaseLayout
      title="Form Single"
      pageContext={pageContext}
      location={location}
    >
      <Form />
    </BaseLayout>
  )
}

export default FormPage
